.elo_price_display {
  --elo-button-font: var(--elo-body-sm-medium);
  --elo-price-color: var(--color-primary-dg-brand);
  --elo-old-price-color: var(--color-primary-dg-400);

  font: var(--elo-button-font);
  display: flex;
  flex-direction: row;
  align-items: baseline;

  &__pricing {
    display: flex;
    flex-direction: row;
    align-items: baseline;
  }

  &--vertical {
    flex-direction: column;

    &.elo_price_display--large {
      gap: 8px;

      .elo_price_display__pricing {
        gap: 8px;
      }
    }

    &.elo_price_display--medium {
      gap: 4px;

      .elo_price_display__pricing {
        gap: 8px;
      }
    }

    &.elo_price_display--small {
      gap: 4px;

      .elo_price_display__pricing {
        gap: 6px;
      }
    }

    &.elo_price_display--extra-small {
      gap: 4px;

      .elo_price_display__pricing {
        gap: 6px;
      }
    }
  }

  &--horizontal {
    flex-direction: row;

    &.elo_price_display--large {
      gap: 8px;

      .elo_price_display__pricing {
        gap: 8px;
      }
    }

    &.elo_price_display--medium {
      gap: 8px;

      .elo_price_display__pricing {
        gap: 8px;
      }
    }

    &.elo_price_display--small {
      flex-wrap: wrap;
      justify-content: center;
      column-gap: 6px;

      .elo_price_display__pricing {
        gap: 6px;
      }
    }

    &.elo_price_display--extra-small {
      flex-wrap: wrap;
      justify-content: center;
      column-gap: 6px;

      .elo_price_display__pricing {
        gap: 6px;
      }
    }
  }

  .elo_price_display__price {
    color: var(--elo-price-color);

    &--crossed {
      text-decoration: line-through;
    }
  }

  .elo_price_display__old_price {
    color: var(--elo-old-price-color);
    text-decoration: line-through;
  }

  .elo_price_display__supportive_text {
    color: var(--elo-old-price-color);
  }

  &.elo_price_display--large {
    .elo_price_display__price {
      font: var(--elo-heading-h4);
    }

    .elo_price_display__old_price {
      font: var(--elo-heading-h6);
    }

    .elo_price_display__supportive_text {
      font: var(--elo-body-xs-regular);
    }
  }

  &.elo_price_display--medium {
    .elo_price_display__price {
      font: var(--elo-heading-h6);
    }

    .elo_price_display__old_price {
      font: var(--elo-body-sm-regular);
    }

    .elo_price_display__supportive_text {
      font: var(--elo-body-xs-regular);
    }
  }

  &.elo_price_display--small {
    .elo_price_display__price {
      font: var(--elo-body-sm-medium);
    }

    .elo_price_display__old_price {
      font: var(--elo-body-sm-regular);
    }

    .elo_price_display__supportive_text {
      font: var(--elo-body-xs-regular);
    }
  }

  &.elo_price_display--extra-small {
    .elo_price_display__price {
      font: var(--elo-body-xs-medium);
    }

    .elo_price_display__old_price {
      font: var(--elo-body-xs-regular);
    }

    .elo_price_display__supportive_text {
      font: var(--elo-body-xs-regular);
    }
  }
}
