.elo-button,
a.elo-button {
  --elo-button-font: var(--elo-body-sm-medium);
  --elo-button-color: var(--color-primary-dg-100);
  --elo-button-background-color: var(--color-primary-dg-brand);
  --elo-button-border-color: var(--color-primary-dg-brand);

  --elo-button-cursor-background-color: var(--color-primary-dg-brand);

  font: var(--elo-button-font);
  font-size: var(--elo-button-font-size);
  line-height: 20px;
  color: var(--elo-button-color);
  background-color: var(--elo-button-background-color);
  border: 1px solid var(--elo-button-border-color);
  display: inline-flex;
  justify-content: center;
  align-items: center;
  gap: 6px;

  padding: var(--elo-button-padding);
  border-radius: 50px;
  cursor: pointer;
  outline: none;
  text-decoration: none;

  &--elevation-1 {
    box-shadow: var(--shadow-elevation-1);
  }

  &--elevation-2 {
    box-shadow: var(--shadow-elevation-2);
  }

  &:focus {
    outline: none;
  }

  &.disabled:active,
  &.disabled:hover,
  &.disabled {
    --elo-button-color: var(--color-primary-dg-300);
    --elo-button-background-color: var(--color-primary-cg-brand);
    --elo-button-border-color: var(--color-primary-cg-brand);
    cursor: not-allowed;
  }

  // variants
  &--highlight {
    --elo-button-color: var(--color-primary-dg-brand);
    --elo-button-background-color: var(--color-highlight-web);
    --elo-button-border-color: var(--color-highlight-web);

    &:hover {
      --elo-button-background-color: var(--color-highlight-hover);
      --elo-button-border-color: var(--color-highlight-hover);
    }

    &:active {
      --elo-button-background-color: var(--color-highlight-click);
      --elo-button-border-color: var(--color-highlight-click);
    }
  }

  &--primary {
    &:hover {
      --elo-button-background-color: var(--color-primary-dg-400);
      --elo-button-border-color: var(--color-primary-dg-400);
    }

    &:active {
      --elo-button-background-color: var(--color-primary-dg-brand);
      --elo-button-border-color: var(--color-primary-dg-brand);
    }
  }

  &--secondary {
    --elo-button-color: var(--color-primary-dg-brand);
    --elo-button-background-color: transparent;
    --elo-button-border-color: var(--color-primary-dg-brand);

    &:hover {
      --elo-button-color: var(--color-primary-dg-400);
      --elo-button-background-color: transparent;
      --elo-button-border-color: var(--color-primary-dg-400);
    }

    &:active {
      --elo-button-color: var(--color-primary-dg-brand);
      --elo-button-background-color: transparent;
      --elo-button-border-color: var(--color-primary-dg-brand);
    }

    &.disabled:active,
    &.disabled:hover,
    &.disabled {
      --elo-button-color: var(--color-primary-dg-300);
      --elo-button-background-color: transparent;
      --elo-button-border-color: var(--color-primary-cg-brand);
    }
  }

  &--danger {
    --elo-button-color: var(--color-primary-dg-100);
    --elo-button-background-color: var(--color-utility-error-dark);
    --elo-button-border-color: var(--color-utility-error-dark);

    &:hover {
      --elo-button-color: var(--color-primary-dg-100);
      --elo-button-background-color: var(--color-utility-error-mid);
      --elo-button-border-color: var(--color-utility-error-mid);
    }

    &:active {
      --elo-button-color: var(--color-primary-dg-100);
      --elo-button-background-color: var(--color-utility-error-dark);
      --elo-button-border-color: var(--color-utility-error-dark);
    }
  }

  &--ghost {
    --elo-button-color: var(--color-primary-dg-brand);
    --elo-button-background-color: transparent;
    --elo-button-border-color: transparent;

    &--active {
      color: var(--color-primary-dg-brand);
      background-color: var(--color-primary-dg-200);
      border-color: var(--color-primary-dg-200);
    }

    &:hover,
    &:focus {
      --elo-button-color: var(--color-primary-dg-400);
      --elo-button-background-color: var(--color-primary-cg-brand);
      --elo-button-border-color: var(--color-primary-cg-brand);
    }

    &:active {
      --elo-button-color: var(--color-primary-dg-brand);
      --elo-button-background-color: var(--color-primary-dg-200);
      --elo-button-border-color: var(--color-primary-dg-200);
    }

    &.disabled:active,
    &.disabled:hover,
    &.disabled {
      --elo-button-color: var(--color-primary-dg-300);
      --elo-button-background-color: transparent;
      --elo-button-border-color: transparent;
    }
  }

  // sizes
  &--small {
    --elo-button-padding: 1px 12px;
    --elo-button-font-size: 12px;
    font-weight: 400;

    &.elo-button--with-icon {
      --elo-button-padding: 1px;
    }

    &.elo-button--with-text.elo-button--with-icon {
      --elo-button-padding: 1px 8px;
    }
  }

  &--medium {
    --elo-button-padding: 5px 15px;
    --elo-button-font-size: 14px;

    &.elo-button--with-icon {
      --elo-button-padding: 5px;
    }

    &.elo-button--with-text.elo-button--with-icon {
      --elo-button-padding: 5px 11px;
    }
  }

  &--large {
    --elo-button-padding: 9px 23px;
    --elo-button-font-size: 14px;

    &.elo-button--with-icon {
      --elo-button-padding: 9px;
    }

    &.elo-button--with-text.elo-button--with-icon {
      --elo-button-padding: 9px 14px;
    }
  }

  &--x-large {
    --elo-button-padding: 13px 27px;
    --elo-button-font-size: 14px;

    &.elo-button--with-icon {
      --elo-button-padding: 13px;
    }

    &.elo-button--with-text.elo-button--with-icon {
      --elo-button-padding: 13px 18px;
    }
  }

  &--full-width {
    width: 100%;
    justify-content: center;
  }

  &__counter {
    width: 20px;
    height: 20px;
    position: absolute;
    bottom: -5px;
    right: -5px;
    background: var(--elo-button-cursor-background-color);
    border-radius: 50%;
    font-size: var(--elo-body-xs-regular-font-size);
    color: var(--color-primary-neutral-white);
  }
}

.elo-button--ghost--active {
  --elo-button-color: var(--color-primary-dg-brand);
  --elo-button-background-color: var(--color-primary-dg-200);
  --elo-button-border-color: var(--color-primary-dg-200);
}

@media (max-width: 568px) {
  .elo-button {
    &--large,
    &--medium {
      --elo-button-padding: 13px 27px;
      --elo-button-font-size: 14px;

      &.elo-button--with-icon {
        --elo-button-padding: 13px;
      }

      &.elo-button--with-text.elo-button--with-icon {
        --elo-button-padding: 13px 18px;
      }
    }
  }
}
