.payment-plan-modal {
  &__header {
    display: flex;
    align-items: center;
    gap: 8px;
  }

  &__price {
    display: flex;
    gap: 8px;
    align-items: baseline;
    margin-bottom: 24px;
  }

  .payment-plan__new-container--coupon-applied {
    border: 1px solid var(--color-highlight-web);

    & > span {
      font: var(--elo-body-xs-regular);
      color: var(--color-primary-neutral-black);
    }
  }
}
