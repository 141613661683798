.elo-payment-item {
  --elo-payment-item-background-color: var(--color-decorative-teal-shade3);
  --elo-payment-item-default-transparency: color-mix(
    in srgb,
    transparent,
    var(--elo-payment-item-background-color) 15%
  );
  --elo-payment-item-first-transparency: color-mix(in srgb, transparent, var(--elo-payment-item-background-color) 25%);
  --elo-payment-item-trial-transparency: color-mix(in srgb, transparent, var(--elo-payment-item-background-color) 40%);

  text-align: center;

  &__container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 4px 8px;
    min-height: 64px;
    min-width: 104px;

    &--default {
      background: var(--elo-payment-item-default-transparency);
    }

    &--first {
      background: var(--elo-payment-item-first-transparency);
    }

    &--trial {
      background: var(--elo-payment-item-trial-transparency);
    }

    &--front-border {
      border-radius: 8px 0 0 8px;
    }

    &--back-border {
      border-radius: 0 8px 8px 0;
    }

    &--flex {
      display: flex;
      align-items: center;
      flex-wrap: wrap;
      gap: 2px;
    }

    &--mobile-time {
      display: none;
    }
  }

  @media (max-width: 768px) {
    &__container {
      padding: 0 0 0 16px;

      &--default {
        background-color: unset;
        border-left: 8px solid var(--elo-payment-item-default-transparency);
      }

      &--first {
        background-color: unset;
        border-left: 8px solid var(--elo-payment-item-first-transparency);
      }

      &--trial {
        background-color: unset;
        border-left: 8px solid var(--elo-payment-item-trial-transparency);
      }

      &--front-border {
        border-radius: unset;
      }

      &--back-border {
        border-radius: unset;
      }

      &--desktop-time {
        display: none;
      }

      &--mobile-time {
        display: block;
      }
    }
  }
}
