@import '../../../styles/colors';
@import '../../../styles/fonts';

$option-size: 20px;
$explosion-distance: 3; // multiplied by $option-size
$explosion-duration: 0.85s;

.radio-field {
  margin-bottom: 30px;

  &__control {
    width: auto;
    display: block;
    position: relative;
    padding-left: 34px;
    cursor: pointer;
    user-select: none;
    line-height: 24px;
    margin: 0;
    font-family: var(--elo-font-sans-serif);
    font-weight: 500;
    color: $elo-grey-dark;

    &--active {
      color: $elo-black-semi;
    }

    &--without-left-padding {
      padding-left: 0;
    }

    input {
      position: absolute;
      opacity: 0;
      cursor: pointer;

      /* When the radio button is selected, add a blue background */
      &:checked ~ .custom-check-mark {
        background-color: var(--radio-checked-bg-color);
        border-color: var(--radio-checked-border-color);

        .boom {
          background: var(--radio-checked-bg-color);
          content: '';
          display: block;
          position: relative;
          border-radius: 50%;
          z-index: 100;
        }
      }

      &:disabled:checked ~ .custom-check-mark {
        background-color: var(--radio-disabled-bg-color);
        border-color: var(--radio-disabled-border-color);
      }

      /* Show the custom-check-mark when checked */
      &:checked ~ .custom-check-mark::before {
        display: block;
      }
    }

    /* Create a custom radio button */
    .custom-check-mark {
      position: absolute;
      top: 0;
      left: 0;
      height: 24px;
      width: 24px;
      background-color: var(--radio-bg-color);
      border-radius: 50%;
      border: 2px solid var(--radio-border-color);

      &::before {
        content: '';
        position: absolute;
        top: 6px;
        left: 6px;
        width: 8px;
        height: 8px;
        border-radius: 50%;
        background: transparent;
      }
    }

    input:checked ~ .custom-check-mark::before {
      background: var(--radio-checked-font-color);
    }

    /* On mouse-over, add a grey background color */
    &:hover input:not(:disabled):not(:checked) ~ .custom-check-mark::before {
      background-color: var(--radio-checked-font-color);
    }
  }
}
