.elo-card {
  --elo-card-background-color: var(--color-primary-neutral-white);
  --elo-card-border-color: var(--color-primary-dg-200);
  --elo-card-hovered-border-color: var(--color-primary-dg-300);
  --elo-card-selected-border-color: var(--color-highlight-web);

  min-width: 360px;
  background-color: var(--elo-card-background-color);
  border: 1px solid var(--elo-card-background-color);
  border-radius: 8px;
  padding: 24px;
  position: relative;

  &,
  * {
    box-sizing: border-box;
  }

  &--without-min-width {
    min-width: unset;
  }

  &--elevation-1 {
    box-shadow: var(--shadow-elevation-1);
  }

  &--elevation-2 {
    box-shadow: var(--shadow-elevation-2);
  }

  &__content {
    margin-bottom: 25px;

    &--without-margin {
      margin-bottom: 0;
    }
  }

  &__selectable {
    &:hover {
      cursor: pointer;
      border: 1px solid var(--elo-card-hovered-border-color);
    }

    &--selected {
      border: 1px solid var(--elo-card-selected-border-color);

      &:hover {
        border: 1px solid var(--elo-card-selected-border-color);
      }
    }
  }

  &__display {
    &:hover {
      cursor: pointer;
      border: 1px solid var(--elo-card-hovered-border-color);
    }
  }

  &__disabled {
    background-color: var(--elo-card-border-color);
    cursor: not-allowed;

    .elo-card__content {
      opacity: 0.5;
    }

    .elo-card__footer {
      opacity: 0.5;
    }
  }

  &__footer {
    display: flex;
    align-items: center;

    &--buttons {
      .elo-button {
        margin-right: 16px;

        &:last-child {
          margin-right: 0;
        }
      }
    }

    &--space-between {
      justify-content: space-between;
    }

    &--packed {
      justify-content: flex-end;

      .elo-card__footer--optional {
        order: 2;
        margin-left: 25px;
      }
    }
  }

  &__info-message {
    width: 100%;
    max-width: 500px;
    padding: 0 15px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
}

@media (max-width: 767px) {
  .elo-card {
    width: 100%;
    min-width: 100%;
    padding: 16px;

    &__content {
      margin-bottom: 16px;

      &--without-margin {
        margin-bottom: 0;
      }
    }

    &__footer {
      flex-wrap: wrap;
      gap: 16px;
    }

    &__footer--buttons .elo-button {
      margin-right: 16px;
    }
  }
}
