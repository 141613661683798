.elo-tooltip {
  --elo-tooltip-background-color: var(--color-primary-dg-brand);
  --elo-tooltip-title-color: var(--color-primary-dg-300);
  --elo-tooltip-title-font: var(--elo-body-xs-regular);
  --elo-tooltip-color: var(--color-primary-dg-100);
  --elo-tooltip-font: var(--elo-body-sm-regular);
  --elo-tooltip-z-index: 1080;

  font: var(--elo-tooltip-font);
  background-color: var(--elo-tooltip-background-color);
  color: var(--elo-tooltip-color);
  padding: 8px;
  border-radius: 8px;
  box-sizing: border-box;
  width: max-content;
  position: absolute;
  z-index: var(--elo-tooltip-z-index);
  display: flex;
  flex-direction: column;
  gap: 4px;

  &__wrapper {
    display: inline-flex;
    position: relative;

    &--grid {
      display: grid;
    }
  }

  &__overflowed {
    @media (min-width: 576px) {
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &__arrow {
    border: 4px solid var(--elo-tooltip-background-color);
    display: block;
    transform: rotate(-45deg);
    position: absolute;
    box-sizing: border-box;
    transform-origin: center center;
    text-align: left;
  }

  &--left {
    right: calc(100% + 8px);
    top: calc(50% - 2px);
    transform: translateY(-50%);

    .elo-tooltip__arrow {
      left: calc(100% - 4px);
      top: calc(50% - 2px);
      right: auto;
    }
  }

  &--right {
    left: calc(100% + 8px);
    top: calc(50% - 2px);
    transform: translateY(-50%);

    .elo-tooltip__arrow {
      right: calc(100% - 4px);
      left: auto;
      top: calc(50% - 2px);
    }
  }

  &--left,
  &--right {
    &.elo-tooltip--start {
      transform: translateY(-15%);

      .elo-tooltip__arrow {
        top: calc(15% - 2px);
      }
    }

    &.elo-tooltip--end {
      transform: translateY(-85%);

      .elo-tooltip__arrow {
        top: calc(85% - 2px);
      }
    }
  }

  &--top {
    left: calc(50% - 2px);
    transform: translateX(-50%);

    .elo-tooltip__arrow {
      top: calc(100% - 4px);
      left: calc(50% - 2px);
      bottom: auto;
    }
  }

  &--bottom {
    top: calc(100% + 8px);
    left: calc(50% - 2px);
    transform: translateX(-50%);

    .elo-tooltip__arrow {
      bottom: calc(100% - 4px);
      left: calc(50% - 2px);
      top: auto;
    }
  }

  &--top,
  &--bottom {
    &.elo-tooltip--start {
      transform: translateX(-15%);

      .elo-tooltip__arrow {
        left: calc(15% - 2px);
      }
    }

    &.elo-tooltip--end {
      transform: translateX(-85%);

      .elo-tooltip__arrow {
        left: calc(85% - 2px);
      }
    }
  }

  &__title {
    font: var(--elo-tooltip-title-font);
    color: var(--elo-tooltip-title-color);
    max-width: 200px;
  }

  &__content {
    max-height: 160px;
    max-width: 200px;
    overflow-y: auto;
    line-height: 17px;
    word-break: break-word;

    a {
      color: var(--elo-white);
    }
  }

  img {
    max-height: 160px;
    max-width: 360px;
  }
}
