.elo-ui-modal {
  --elo-full-screen-modal-background-color: var(--color-primary-neutral-white);
  --elo-modal-background-color: var(--color-primary-neutral-white);
  --elo-modal-overlay-color: var(--color-primary-dg-brand);
  --elo-modal-zindex: 1060;

  --elo-modal-close-button-color: var(--color-primary-dg-300);

  --elo-modal-title-font: var(--elo-heading-h3);
  --elo-modal-title-color: var(--color-primary-dg-brand);

  --elo-modal-message-font: var(--elo-body-sm-regular);
  --elo-modal-message-color: var(--color-primary-dg-brand);

  --elo-modal-header-font: var(--elo-heading-h4);
  --elo-modal-body-font: var(--elo-body-sm-regular);

  $this: &;
  position: fixed;
  top: 0;
  left: 0;
  z-index: var(--elo-modal-zindex);
  width: 100%;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  outline: 0;

  &__dialog {
    position: relative;
    width: auto;
    pointer-events: none;
    margin: 5% auto;
    height: 100%;
  }

  &__content {
    display: flex;
    flex-direction: column;
    position: relative;
    width: 100%;
    pointer-events: auto;
    background-color: var(--elo-modal-background-color);
    border-radius: 18px;
    outline: 0;
    padding: 56px 48px;
    box-sizing: border-box;
    box-shadow: var(--shadow-elevation-3);
  }

  &__overlay {
    position: fixed;
    top: 0;
    left: 0;
    z-index: -1;
    width: 100%;
    bottom: 0;
    background-color: var(--elo-modal-overlay-color);
    opacity: 0.6;
    pointer-events: none;
  }

  &__close-button {
    display: flex;
    cursor: pointer;
    position: absolute;
    right: 26px;
    top: 23px;
    gap: 10px;
    color: var(--elo-modal-close-button-color);
  }

  &__header {
    font: var(--elo-heading-h4);
    margin-bottom: 25px;
    line-height: 1;

    &--with-icon {
      display: flex;
      align-items: center;

      svg {
        margin-right: 18px;
      }
    }

    &--center {
      text-align: center;
    }
  }

  &__body {
    font: var(--elo-modal-body-font);
    margin-bottom: 25px;
    line-height: 22px;
    max-height: calc(100vh - 320px);
    overflow: auto;

    &.overflow {
      border-bottom: 1px solid var(--color-primary-dg-200);
    }

    &--center {
      text-align: center;
    }

    &--no-overflow {
      overflow: visible;
      border-bottom: none !important;
    }
  }

  &--small {
    #{$this}__dialog {
      margin: 5% auto;
      max-width: 768px;
    }
  }

  &--medium {
    #{$this}__dialog {
      max-width: 868px;
    }
  }

  &--large {
    #{$this}__dialog {
      max-width: 968px;
    }
  }

  &--x-large {
    #{$this}__dialog {
      max-width: 1068px;
    }
  }

  &--fullscreen {
    background-color: var(--color-primary-dg-100);
    #{$this}__dialog {
      max-width: 100%;
      margin: 0;
    }

    #{$this}__content {
      overflow: auto;
      position: relative;
      pointer-events: auto;
      margin: 0;
      background-color: var(--elo-full-screen-modal-background-color);
      outline: 0;
      padding: 96px 77px 74px;
      border-radius: 0;
      height: 100%;
    }
    #{$this}__body {
      height: 100%;
    }
  }

  &--with-fixed-overlay {
    #{$this}__dialog {
      height: auto;
      margin: 3% auto;
    }
    overflow: hidden;
  }

  &__footer {
    display: flex;
    justify-content: flex-start;
    column-gap: 10px;

    &--between {
      justify-content: space-between;
    }

    &--center {
      justify-content: center;
    }

    &--end {
      justify-content: flex-end;
    }

    &--pagination {
      justify-content: flex-end;
      gap: var(--elo-space-16);
      flex-direction: column;
      .elo-paginate {
        margin-top: 0px;
      }
    }

    &--pagination-buttons {
      align-self: end;
      .elo-button + .elo-button {
        margin-left: 16px;
      }
    }
  }
}
