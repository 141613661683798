.elo-payment-time {
  --elo-payment-date-color: var(--color-primary-dg-brand);
  --elo-payment-date-label-font: var(--elo-body-xs-regular);
  --elo-payment-date-font: var(--elo-body-xs-semibold);

  &__date {
    color: var(--elo-payment-date-color);
    font: var(--elo-payment-date-label-font);

    span {
      font: var(--elo-payment-date-font);
    }
  }
}
