.elo-status-badge {
  $this: &;

  --elo-status-badge-font: var(--elo-body-sm-medium);
  --elo-status-badge-color: var(--color-primary-dg-brand);
  --elo-status-badge-background-color: none;

  display: inline-flex;
  align-items: center;
  gap: 4px;
  font: var(--elo-status-badge-font);
  padding: 2px 4px 2px 4px;
  line-height: 20px;
  border-radius: 8px;
  color: var(--elo-status-badge-color);
  background-color: var(--elo-status-badge-background-color);
  cursor: default;

  &--disabled {
    opacity: 0.4;
  }

  &--blocked {
    &#{$this}--active {
      &:hover {
        --elo-status-badge-color: var(--color-primary-dg-400);
      }
    }

    &#{$this}--highlight {
      --elo-status-badge-background-color: var(--color-primary-dg-100);
    }
  }

  &--clickable {
    cursor: pointer;
  }

  &--success {
    --elo-status-badge-color: var(--color-utility-success-dark);

    &#{$this}--active {
      &:hover {
        --elo-status-badge-color: var(--color-utility-success-mid);
      }
    }

    &#{$this}--highlight {
      --elo-status-badge-background-color: var(--color-utility-success-light);
    }
  }

  &--warning {
    --elo-status-badge-color: var(--color-utility-warning-dark);

    &#{$this}--active {
      &:hover {
        --elo-status-badge-color: var(--color-utility-warning-mid);
      }
    }

    &#{$this}--highlight {
      --elo-status-badge-background-color: var(--color-utility-alert-light);
    }
  }

  &--error {
    --elo-status-badge-color: var(--color-utility-error-dark);

    &#{$this}--active {
      &:hover {
        --elo-status-badge-color: var(--color-utility-error-mid);
      }
    }

    &#{$this}--highlight {
      --elo-status-badge-background-color: var(--color-utility-error-light);
    }
  }

  &--in-review {
    --elo-status-badge-color: var(--color-primary-dg-brand);

    &#{$this}--active {
      &:hover {
        --elo-status-badge-color: var(--color-primary-dg-400);
      }
    }

    &#{$this}--highlight {
      --elo-status-badge-background-color: var(--color-primary-wg-brand);
    }
  }
}
