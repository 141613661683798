.elo-info-message {
  --elo-info-message-color: var(--color-utility-success-dark);
  --elo-info-message-background-color: var(--color-utility-success-light);
  --elo-info-message-font: var(--elo-body-sm-regular);

  font: var(--elo-info-message-font);
  color: var(--elo-info-message-color);
  background-color: var(--elo-info-message-background-color);
  box-shadow: var(--shadow-elevation-2);
  padding: 8px 16px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;

  &::before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    width: 4px;
    height: 100%;
    background-color: var(--elo-info-message-border-color);
  }

  &__title {
    align-items: flex-start;
    display: flex;

    &--title {
      display: flex;
      gap: 4px;
      align-items: normal;
      padding-right: 12px;
    }

    &--start {
      justify-content: flex-start;
    }

    &--center {
      justify-content: center;
    }

    &--action {
      justify-content: space-between;

      .elo-info-message__title--title {
        flex: 1 1 80%;
      }
    }
  }

  &__text {
    color: var(--elo-info-message-text-color);
  }

  &--success {
    --elo-info-message-color: var(--elo-utility-success-dark);
    --elo-info-message-text-color: var(--elo-utility-success-dark);
    --elo-info-message-background-color: var(--elo-utility-success-light);
    --elo-info-message-border-color: var(--elo-utility-success-dark);
  }

  &--warning {
    --elo-info-message-background-color: var(--color-utility-alert-light);
    --elo-info-message-color: var(--color-utility-warning-dark);
    --elo-info-message-text-color: var(--color-utility-warning-dark);
    --elo-info-message-border-color: var(--color-utility-warning-dark);
  }

  &--error {
    --elo-info-message-background-color: var(--color-utility-error-light);
    --elo-info-message-color: var(--color-utility-error-dark);
    --elo-info-message-text-color: var(--color-utility-error-dark);
    --elo-info-message-border-color: var(--color-utility-error-dark);
  }

  &--information {
    --elo-info-message-background-color: var(--color-primary-dg-100);
    --elo-info-message-color: var(--elo-neutral-black);
    --elo-info-message-border-color: var(--elo-neutral-black);
    --elo-info-message-text-color: var(--elo-primary-dg-400);
  }

  &--medium {
    padding: 8px 16px;

    .elo-info-message__title {
      padding-bottom: 10px;
      --elo-info-message-font: var(--elo-body-sm-medium);
    }
  }

  &__action-buttons {
    display: flex;
    gap: 8px;
    flex: 1 1 20%;
    justify-content: flex-end;

    .elo-button:not(:hover) {
      color: inherit;
      &:not(.elo-button--ghost):not(:hover) {
        border-color: inherit;
      }
    }
  }

  svg {
    flex-shrink: 0;
  }
}
