.elo-payment-plan {
  display: flex;
  gap: 4px;
  overflow: hidden;
  position: relative;

  &--overflowed {
    &::after {
      content: '';
      position: absolute;
      z-index: 1;
      top: 0;
      right: 0;
      pointer-events: none;
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) 0%, var(--color-primary-neutral-white) 100%);
      width: 30%;
      height: 96px;
    }
  }

  &__item {
    &--full-width {
      width: 100%;
      min-width: fit-content;
    }
  }

  &__plan-button {
    margin-top: 16px;
  }

  &__mobile-plan-button {
    display: none;
  }

  &--in-modal {
    flex-wrap: wrap;
    gap: 4px 8px;

    .elo-payment-plan__item {
      flex-basis: 210px;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
    align-items: baseline;

    &--overflowed {
      &::after {
        content: none;
        top: unset;
      }
    }

    &--mobile-overflowed {
      &::after {
        content: '';
        position: absolute;
        z-index: 1;
        bottom: 20px;
        left: 0;
        pointer-events: none;
        background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, var(--color-primary-neutral-white) 77.5%);
        width: 100%;
        height: 64px;
      }
    }

    &__item {
      .elo-payment-item {
        &__container {
          align-items: baseline;
        }
      }

      &--hidden {
        display: none;
      }
    }

    &__plan-button {
      display: none;
    }

    &__mobile-plan-button {
      display: block;
      width: 100%;
      text-align: center;
    }

    &--in-modal {
      .elo-payment-plan__item {
        flex-basis: unset;
      }
    }
  }
}
