@import 'src/ui-kit/styles/fonts';
@import 'src/ui-kit/styles/colors';

.payment-plan {
  &__countdown {
    width: 100%;
    border-radius: 4px;
    margin-top: 10px;
    padding: 8px 16px;
    text-align: center;

    &--text {
      font-size: var(--elo-font-size-sm);
      font-family: var(--elo-font-sans-serif);
      margin-bottom: 8px;
    }

    &--timer {
      display: flex;
      justify-content: center;
    }

    &--divider {
      font-family: var(--elo-font-sans-serif);
      margin: 0 5px;
    }

    &--day-count,
    &--hour-count,
    &--min-count,
    &--sec-count {
      font-size: var(--elo-font-size-lg);
      font-family: var(--elo-font-sans-serif);
    }

    &--day-text,
    &--hour-text,
    &--min-text,
    &--sec-text {
      font-size: 10px;
    }
  }
}
