.elo-radio-button {
  --elo-radio-button-label-font: var(--elo-body-sm-medium);
  --elo-radio-button-label-color: var(--color-primary-dg-brand);
  --elo-radio-button-box-background-color: var(--color-primary-dg-100);
  --elo-radio-button-box-border-color: var(--color-primary-dg-brand);
  --elo-radio-button-box-dot-color: var(--color-primary-dg-100);
  --elo-radio-button-box-highlight-border-color: var(--color-highlight-click);
  --elo-radio-button-box-checked-color: var(--color-primary-dg-brand);
  --elo-radio-button-tooltip-color: var(--color-primary-dg-400);

  display: inline-flex;
  align-items: center;
  cursor: pointer;
  gap: 8px;
  color: var(--elo-radio-button-box-border-color);

  &__no-margin {
    margin-bottom: 0;
  }

  &__label {
    font: var(--elo-radio-button-label-font);
    color: var(--elo-radio-button-label-color);
  }

  &__label-tooltip {
    display: flex;
    align-items: center;
    color: var(--color-primary-dg-400);
    line-height: 1;
  }

  &__box {
    display: flex;
    min-width: 22px;
    height: 22px;
    justify-content: center;
    align-items: center;
    border-radius: 50%;
    box-sizing: border-box;
    position: relative;

    &--extra-small {
      min-width: 16px;
      height: 16px;
    }
  }

  &__input {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    position: absolute;

    &:focus:not(:disabled):not(:checked) + .elo-radio-button__box,
    &:hover:not(:disabled):not(:checked) + .elo-radio-button__box {
      color: var(--elo-radio-button-box-highlight-border-color);
    }

    &:checked:focus:not(:disabled) + .elo-radio-button__box,
    &:checked:hover:not(:disabled) + .elo-radio-button__box {
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        border: 1px solid var(--elo-radio-button-box-highlight-border-color);
        border-radius: 50%;
        box-sizing: border-box;
      }
    }

    &:checked + .elo-radio-button__box {
      color: var(--elo-radio-button-box-checked-color);
    }

    &:disabled {
      & + .elo-radio-button__box {
        --elo-radio-button-box-background-color: var(--color-primary-dg-400);
        --elo-radio-button-box-border-color: var(--color-primary-dg-400);
        --elo-radio-button-box-dot-color: var(--color-primary-dg-400);
        color: var(--color-primary-dg-400);

        & + .elo-radio-button__label {
          color: var(--color-primary-dg-400);

          & + .elo-radio-button__label-tooltip {
            --elo-radio-button-tooltip-color: var(--color-primary-dg-400);
          }
        }
      }

      &:checked {
        & + .elo-radio-button__box {
          --elo-radio-button-box-background-color: var(--color-primary-dg-400);
          --elo-radio-button-box-border-color: var(--color-primary-dg-400);
          --elo-radio-button-box-dot-color: var(--color-primary-dg-100);

          & + .elo-radio-button__label {
            --elo-radio-button-label-color: var(--color-primary-dg-400);
          }
        }
      }
    }
  }
}
