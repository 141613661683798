.elo-link {
  --elo-link-font: var(--elo-body-sm-regular);
  --elo-link-color: var(--color-primary-dg-brand);
  --elo-link-color--hover: var(--color-primary-dg-400);
  --elo-link-color--active: var(--color-primary-dg-500);
  --elo-link-color--disabled: var(--color-primary-dg-300);
  --elo-link-color-light: var(--color-primary-neutral-white);
  --elo-link-color-light--hover: var(--elo-primary-dg-200);
  --elo-link-color-light--disabled: var(--elo-primary-dg-400);
  cursor: pointer;
  text-decoration: underline;
  color: var(--elo-link-color);
  font: var(--elo-link-font);
  line-height: 18px;
  outline: none;

  &:hover,
  &:focus {
    color: var(--elo-link-color--hover);
  }

  &:active {
    color: var(--elo-link-color--active);
  }

  &--highlight {
    color: var(--color-highlight-web);
  }

  &--disabled {
    color: var(--elo-link-color--disabled);
    pointer-events: none;
    cursor: not-allowed;
  }

  &--light {
    color: var(--elo-link-color-light);

    &:hover {
      color: var(--elo-link-color-light--hover);
    }
  }

  &--disabled {
    &.elo-link--light {
      color: var(--elo-link-color-light--disabled);
    }
  }
}
